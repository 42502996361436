<template>
  <section class="hero">
    <div class="hero-content">
      <h1 class="hero-heading">trax men</h1>
      <a href="" class="discover-more">Discover more</a>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
.hero {
  background-image: url("../../assets/hero/hero.jpg");
  height: 75vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  margin-bottom: 5px;
  border: solid 7px;
  border-color: rgba(218, 215, 215, 0.467);
}

.hero-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 0 2rem;
  align-items: center;
  height: 100%;
}

.hero-heading {
  font-family: "Satisfy", cursive;
  font-size: 90px;
  color: white;
}

.discover-more {
  padding: 0.5rem 0.9rem;
  color: #ffffff;
  background: transparent;
  border: solid 2px;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
}
</style>