<template>
  <header>
    <div class="topbar">
      <div class="left-topbar">
        <div class="socials">
          <span class="links"><i class="fab fa-twitter topbar-link"></i></span>
          <span class="links"
            ><i class="fab fa-facebook-f topbar-link"></i
          ></span>
          <span class="links"
            ><i class="fab fa-snapchat-ghost topbar-link"></i
          ></span>
          <span class="links"
            ><i class="fab fa-instagram topbar-link"></i
          ></span>
        </div>
        <div class="left-topbar-right">
          <div class="store topbar-link">Store visit</div>
          <div class="apt topbar-link">
            Book an appointment <span><i class="fas fa-angle-right"></i></span>
          </div>
        </div>
      </div>
      <div class="right-topbar">
        <div class="right-topbar-left">
          <div class="sales topbar-link">Hot sales !!</div>
          <div class="s-collection topbar-link">
            See sales collections
            <span><i class="fas fa-angle-right"></i></span>
          </div>
        </div>
        <div class="email topbar-link">
          <span>Appointment@traxapparel.fashion</span>
        </div>
      </div>
    </div>
    <div class="navbar">
      <h1 class="row-1">
        <a href="https://ut-colab-interview.onrender.com/"
          ><img class="logo" src="@/assets/header/logo.jpeg" alt=""
        /></a>
      </h1>
      <div class="row-2">
        <div class="nav-links">
          <ul>
            <li>Men</li>
            <li>Women</li>
            <li>Kids</li>
            <li>Accessories</li>
            <li>Collections</li>
          </ul>
        </div>
        <div class="search-box">
          <search-box />
        </div>
      </div>
      <div class="row-3">
        <div class="row-3-link">
          <i class="fa fa-user"></i>
          <span>Sign in</span>
        </div>
        <div class="row-3-link">
          <i class="fa fa-shopping-bag"></i>
          <span>Cart</span>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import SearchBox from "@/components/ui/SearchBox";
export default {
  components: {
    SearchBox,
  },
};
</script>

<style scoped>
header {
  font-size: 10px;
}

.navbar {
  display: grid;
  grid-template-columns: 1fr 13fr 1fr;
  width: 100%;
  padding: 1rem 6rem;
}

.row-1 .logo {
  width: 50px;
  /* cursor: pointer; */
}

.nav-links {
  color: rgb(150, 150, 150);
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
}

.row-2 {
  display: flex;
  align-items: center;
}

.nav-links ul {
  display: flex;
  align-items: center;
}

.nav-links ul li {
  padding: 0.2rem;
  margin: 0 0.6rem;
  cursor: pointer;
}

.nav-links ul li:first-child {
  margin-left: 0;
}

i {
  color: rgb(150, 150, 150);
}

.row-3 {
  text-align: center;
  display: flex;
  align-items: center;
  color: rgb(150, 150, 150);
}

.row-3 i {
  margin: 0 1.3rem;
}

.row-3-link {
  cursor: pointer;
}

.row-3 span {
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
}

.topbar {
  display: flex;
  background: rgb(43, 43, 43);
  font-weight: 600;
}

.left-topbar {
  flex: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  padding-left: 6rem;
}

.right-topbar {
  flex: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  background: rgb(214, 214, 214);
  padding-right: 6rem;
}

.left-topbar .socials {
  display: flex;
  align-items: center;
}

.left-topbar .socials .links {
  padding: 2px;
  margin: 0 2px;
}

.left-topbar-right {
  display: flex;
  align-items: center;
  color: rgb(150, 150, 150);
  text-transform: uppercase;
}

.topbar .left-topbar .left-topbar-right .store {
  padding: 5px 10px;
  border: solid thin black;
}

.topbar .left-topbar .left-topbar-right .apt {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  border: solid thin transparent;
  background: black;
}

.topbar i {
  padding-left: 12px;
}

.right-topbar-left {
  display: flex;
  align-items: center;
  text-transform: uppercase;
}

.right-topbar i {
  color: black;
}

.topbar .right-topbar .right-topbar-left .sales {
  padding: 5px 10px;
  border: solid thin transparent;
  color: rgb(150, 150, 150);
  background: black;
}

.topbar .right-topbar .right-topbar-left .s-collection {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  border: solid thin transparent;
  background: goldenrod;
}

.topbar .right-topbar .email {
  text-transform: uppercase;
  letter-spacing: 1px;
}

.topbar-link {
  cursor: pointer;
}
</style>