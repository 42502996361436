<template>
  <section class="in-trend">
    <h3>Be in <span class="hashtag">#trend</span></h3>
    <div class="imgs-row">
      <div v-for="img in showcase" :key="img.url">
        <div><img :src="img.url" :alt="title" class="product-image" /></div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      showcase: [
        { url: require("@/assets/intrend/img1.jpg") },
        { url: require("@/assets/intrend/img2.jpg") },
        { url: require("@/assets/intrend/img3.jpg") },
        { url: require("@/assets/intrend/img4.jpg") },
      ],
    };
  },
};
</script>

<style scoped>
.in-trend {
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 7px;
  text-transform: uppercase;
  text-align: center;
  padding-top: 3rem;
}

.in-trend .imgs-row {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5px;
}

.in-trend h3 {
  color: white;
}

.in-trend .hashtag {
  color: yellow;
}

.imgs-row {
  margin: 2.5rem 6rem;
}
</style>