<template>
  <div class="homepage">
    <the-header />
    <hero />
    <showcase />
    <in-trend />
    <the-footer />
  </div>
</template>

<script>
import TheFooter from "@/components/nav/TheFooter.vue";
import Showcase from "@/components/homepage/Showcase.vue";
import InTrend from "@/components/homepage/InTrend.vue";
import Hero from "@/components/homepage/Hero.vue";
import TheHeader from "@/components/nav/TheHeader";

export default {
  components: {
    TheFooter,
    Showcase,
    InTrend,
    Hero,
    TheHeader,
  },
};
</script>

<style scoped>
.homepage {
  background: black;
  overflow: hidden;
}
</style>