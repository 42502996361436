<template>
  <footer>
    <div class="footer-row">
      <div class="row-1">
        <h5>About Trax</h5>
        <ul>
          <li><a href="" class="footer-link">About Us</a></li>
          <li><a href="" class="footer-link">Careers at Trax</a></li>
          <li><a href="" class="footer-link">Trax Lifestyle</a></li>
        </ul>
      </div>
      <div class="row-2">
        <h5>Help &amp; Information</h5>
        <ul>
          <li><a href="" class="footer-link">My Account/Order</a></li>
          <li><a href="" class="footer-link">Contact Us</a></li>
          <li><a href="" class="footer-link">Frequently Asked Questions</a></li>
        </ul>
      </div>
      <div class="row-3">
        <h5>Delivery Information</h5>
        <ul>
          <li><a href="" class="footer-link">Delivery Return Policy</a></li>
          <li><a href="" class="footer-link">Shipping &amp; Delivery</a></li>
        </ul>
      </div>
      <div class="row-4">
        <h5>Supported</h5>
        <ul class="supported-means">
          <li><img src="@/assets/footer/mastercard-logo.png" alt="" /></li>
          <li><img src="@/assets/footer/visa-logo.svg" alt="" /></li>
          <li><img src="@/assets/footer/paypal-logo.svg" alt="" /></li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style scoped>
.footer-row {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 50px;
  margin: 2rem 6rem 0 6rem;
  background: rgb(43, 43, 43);
  color: white;
}

.footer-row h5 {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
}

.footer-row li {
  margin: 20px 0;
}

.footer-link {
  font-size: 12px;
  color: rgb(150, 150, 150);
}

.supported-means {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.supported-means img {
  width: 50px;
}
</style>