<template>
  <section class="showcase">
    <div class="imgs-row">
      <div v-for="img in showcase" :key="img.url">
        <div><img :src="img.url" :alt="title" class="product-image" /></div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      showcase: [
        { url: require("@/assets/showcase/img1.jpg") },
        { url: require("@/assets/showcase/img2.jpg") },
        { url: require("@/assets/showcase/img3.jpg") },
      ],
    };
  },
};
</script>

<style scoped>
.showcase .imgs-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
}
</style>