<template>
  <form @submit.prevent="">
    <button><i class="fa fa-search"></i></button>
    <input type="text" placeholder="Search" />
  </form>
</template>

<script>
export default {};
</script>

<style scoped>
form {
  margin-left: 1.5rem;
}

button {
  padding: 10px;
  border: none;
  border-radius: 50px 0 0 50px;
  background: rgb(43, 43, 43);
  color: rgb(150, 150, 150);
  font-size: 10px;
  outline: none;
  cursor: pointer;
}

input {
  width: 220px;
  padding: 10px 0;
  color: rgb(150, 150, 150);
  background: rgb(43, 43, 43);
  border: none;
  border-radius: 0 50px 50px 0;
  font-size: 10px;
  outline: none;
}
</style>