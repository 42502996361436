<template>
  <div id="app">
    <homepage />
  </div>
</template>

<script>
import Homepage from "@/pages/Homepage";

export default {
  name: "App",
  components: {
    Homepage,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Satisfy&display=swap');

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font: inherit;
}

html {
  font-family: "Poppins", sans-serif;
}

img {
  width: 100%;
}

ul {
  list-style: none;
}

li {
  margin: 0;
}

a {
  text-decoration: none;
  display: inline-block;
}
</style>
